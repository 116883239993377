<template>
  <div id="notificationId" class="overlay">
    <div class="container-notification">
      <div class="notification">
        <img src="../assets/LOGO-Download.png" alt="" width="55" height="50">
        <p>Create a shortcut to the Audioguide</p>
        <button class="btn-style" @click="install()">Aceptar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "NotificationDownload",
  data() {
    return {
      h: null,
    };
  },
  computed: {
    ...mapState({
      deferredPrompt: (state) => state.app.deferredPrompt,
    }),
    getDeferred() {
      return this.deferredPrompt;
    },
  },
  mounted() {
    const isAppInstalled = localStorage.getItem("isAppInstalled");
    localStorage.setItem("url",JSON.stringify(window.location.href));
    if (isAppInstalled) {
      this.hideNotification();
    }
  },
  created() {
    window.addEventListener("appinstalled", this.appInstalledHandler);
  },
  beforeDestroy() {
    window.removeEventListener("appinstalled", this.appInstalledHandler);
  },
  methods: {
    ...mapActions("app", ["fillDeferredPrompt"]),
    appInstalledHandler() {
      this.deferredPrompt = null;
      localStorage.setItem("isAppInstalled", true);
      this.hideNotification();
    },
    async install() {
      if (this.deferredPrompt) {
        try {
          await this.deferredPrompt.prompt();
          const currentURL = window.location.href;
          const choiceResult = await this.deferredPrompt.userChoice;
           
     if (choiceResult.outcome === 'accepted') {
        history.replaceState({}, document.title, currentURL);
        }
        } catch (error) {
          console.error("An error occurred while calling the prompt:", error);
        }
      }
    },
    hideNotification() {
      const downloadButton = document.getElementById("notificationId");
      if (downloadButton) {
        downloadButton.style.display = "none";
      }
    },
  },
};
</script>

<style scoped>
.notification {
  background-color: #fff;
  color: black;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  width: 185px;
}
.container-notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-style {
  background-color: #d61147;
  color: #fff;
  padding: 7px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 25px;
  margin-top: 10px;
  width: 147px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
</style>
